.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Prevent overflow */
}

.heroContainer {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: black;
  padding: 0;
  overflow: hidden; /* Prevent overflow */
}

.textContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: relative;
}

@font-face {
  font-family: 'Termina';
  src: url('./Font/TerminaTest-Demi.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.yuzYapi {
  font-size: 100px;
  font-weight: 500;
  margin: 0;
  font-family: 'Termina', sans-serif;
  color: white;
}

.projectName {
  font-size: 24px;
  margin: 0;
}

.imageContainer {
  flex: 1;
}

.logo {
  width: 200px; /* Adjust size as needed */
  height: auto;
  margin-left: 160px;
  margin-right: 30px; /* Adjust space between logo and text */
}
.yuzyapilogo{
  width: 200px;
  display: none;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-120%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.yuzYapi, .dots, .logo {
  animation: slideInFromLeft 2s ease-out forwards;
  animation-delay: 0s;
}

.dots {
  position: absolute;
  top: -112px; /* Adjust position as needed */
  left: 485px; /* Adjust position as needed */
  z-index: 10;
  font-size: 130px; /* Adjust size as needed */
  font-weight: 300;
  margin: 0;
  font-family: 'Arial', sans-serif;
  color: white;
  animation-delay: 0.2s; /* Adjust delay to sync with .yuzYapi */
}

.heroImage {
  width: auto;
  height: 97vh;
  margin-left: 150px;
}

.sneakPeek {
  margin: 20px 0;
  text-align: left;
  font-size: 30px;
  font-family: 'Arial', sans-serif;
  background-color: white;
  padding: 20px;
  margin-bottom: 100px;
}

.tepeLoftSneakPeekHeader {
  font-family: 'Termina', sans-serif;
  font-weight: bolder;
  font-size: 50px; /* Adjust size as needed */
  margin-bottom: 55px;
  margin-left: 200px;
}

.sneakPeekGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: center;
  padding: 0 80px; /* Adding space to container sides */
}

.sneakPeekImage {
  width: 80%;
  height: auto;
  margin-left: 130px;
}

.sneakPeekText {
  font-size: 14px; /* Slightly smaller text */
}

.detailsLink {
  text-decoration: none;
  color: #000;
  font-weight: bold;
  font-family: 'Helvetica', sans-serif;
}

.footerContainer {
  width: 100%;
}

.loftSpecs {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  background-color: black;
  padding: 20px 11px; /* Adding space to container sides */
  margin: auto;
  gap: 20px; /* Adding gap between items */
}

.loftSpec {
  margin-top: 70px;
  margin-bottom: 60px;
  width: 18%; /* Adjust the size as needed */
  text-align: center;
  color: white;
}

.loftSpec img {
  width: 94%; /* Smaller image size */
  height: auto;
  margin-bottom: 10px; /* Adding space between image and text */
}

.firstLine {
  font-family: 'Monaco', Monospace; /* Use the specified font */
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 10px 0;
}

.secondLine {
  font-family: 'Arial', sans-serif; /* Use the specified font */
  font-size: 16px;
  margin: 5px 0 0 0;
}

@media (max-width: 1200px) {
  .yuzYapi {
    font-size: 80px;
  }

  .dots {
    top: -90px;
    left: 400px;
    font-size: 100px;
  }

  .tepeLoftSneakPeekHeader {
    font-size: 40px;
    margin-left: 150px;
  }

  .sneakPeek {
    font-size: 24px;
  }

  .sneakPeekGrid {
    padding: 0 60px;
  }

  .loftSpecs {
    flex-direction: column;
    align-items: center;
  }

  .loftSpec {
    width: 60%;
  }
}

@media (max-width: 768px) {
  .yuzYapi {
    font-size: 60px;
  }

  .dots {
    top: -70px;
    left: 300px;
    font-size: 80px;
  }

  .tepeLoftSneakPeekHeader {
    font-size: 30px;
    margin-left: 100px;
  }

  .sneakPeek {
    font-size: 18px;
  }

  .sneakPeekGrid {
    grid-template-columns: 1fr;
    padding: 0 40px;
  }

  .loftSpec {
    width: 80%;
  }
}


@media (max-width: 480px) {
  .heroContainer {
    flex-direction: column;
    height: 25vh; /* Increase height for small screens */
  }

  .yuzYapi {
    font-size: 40px;
    text-align: center;
    display: none;

  }

  .dots {
    top: -30px; /* Adjusted for better alignment */
    left: 50%; /* Centered horizontally */
    transform: translateX(-50%);
    font-size: 60px;
    display: none; /* Hide the dots on small screens */
  }

  .heroImage {
    display: none; /* Hide the hero image */
  }

  .logo {
    width: 100px; /* Adjusted size for smaller screens */
    margin-top: 50px;
    margin-left: 0; /* Adjust space between logo and text */
    margin-right: 18px; /* Adjust space between logo and text */
    display: none;
  }
 .yuzyapilogo{
  margin-top: 40px;
  width:68%;
  height: auto;
  display: block;
 }
  .textContainer {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .tepeLoftSneakPeekHeader {
    font-size: 28px;
    margin-left: 50px;
  }

  .sneakPeek {
    font-size: 14px;
  }

  .sneakPeekImage {
    margin-left: 30px;
  }

  .sneakPeekGrid {
    padding: 0 20px;
  }

  .loftSpec {
    width: 80%;
  }
}