.topContainer {
  width: 100%;
  height: 18vh;
  background-color: black; /* Change as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  overflow: hidden; /* Ensure the image fits within the container */
}

.topImage {
  width: auto;
  height: 12vh;
  object-fit: cover; /* Ensure the image covers the container without stretching */
}
