.footer {
  background: #000;
  color: #fff;
  padding: 60px 30px; /* Reduced padding for smaller screens */
  margin-top: 0px; /* Set margin top to 0 to remove the space */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerContent {
  display: flex;
  flex-direction: column; /* Stacked layout on smaller screens */
  width: 100%;
  margin-top: -34px;
  justify-content: center; /* Center content horizontally */
  flex-wrap: wrap; /* Allow wrapping */
}

.footerLinks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center; /* Centered alignment */
  margin: 10px 0; /* Adjusted margin */
}

.footerLinks a {
  text-decoration: none;
  margin: 10px 28px; /* Adjusted spacing between links */
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 25px; /* Slightly smaller font size */
  font-weight: bold;
}

.icon {
  margin-right: 10px;
}

.coordinates {
  font-family: 'Montserrat', sans-serif;
  font-size: 36px; /* Adjusted font size */
  color: white;
  margin-top: 10px;
  margin-bottom: -25px;
  text-align: center; /* Centered coordinates */
  flex-basis: 100%;
}

.socialIcons {
  display: flex;
  justify-content: center;
  margin: 7px 0;
  flex-basis: 100%;
  margin-top: 20px;
  margin-bottom: -5px;
}

.socialIcons a {
  margin: 0 15px; /* Reduced spacing between icons */
  color: #0066ff;
}

.footerCopyright {
  text-align: center;
  margin-top: 20px;
  flex-basis: 100%;
}

.footerCopyright p a {
  color: #fff;
  text-decoration: none;
}
