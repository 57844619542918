.about {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: #ffffff;
  text-align: center;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
  text-align: center;
}

.textContainer {
  max-width: 800px;
  margin: 0 auto;
  text-align: justify;
}

.paragraph {
  position: relative;
  margin: 40px 0;
}

.backgroundText {
  font-family: 'Termina', sans-serif;
  font-size: 36px; /* Smaller font size for headers */
  font-weight: bold;
  color: #2b2b2b;
  margin-bottom: 20px;
  text-align: center;
  z-index: 1;
  position: relative;
}

.foregroundText {
  font-family: 'Termina', sans-serif;
  font-size: 36px;
  color: #2B2B2B;
  margin: 40px 0;
  animation: slideInFromRight 1.5s forwards;
}

@media (max-width: 988px) {
  .backgroundText {
    font-size: 28px;
  }

  .foregroundText {
    font-size: 28px;
  }

  .textContainer {
    padding: 0 20px;
  }
}

@media (max-width: 480px) {
  .backgroundText {
    font-size: 24px;
  }

  .foregroundText {
    font-size: 28px;
  }

  .textContainer {
    padding: 0 15px;
  }
  
}
