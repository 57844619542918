/* Navbar.module.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.76rem 2rem;
  margin-top: 14px;
  background-color: #ffffff00;
  position: fixed;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  transition: top 1.1s ease;
  top: 0;
  left: 0;
  z-index: 100;
}
.hide {
  top: -100%; /* Adjust based on the actual height of your navbar */
}
.logo {
  flex-grow: 1;
  color: #000;
  font-size: large;
}
.navLink {
  color: #fefefe;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-family: 'Inter', sans-serif;
  font-weight:bold;
  font-size: 1.46rem;
  margin: 0 8px;
  text-shadow:
    -1px -1px 1px #000,
    1px -1px 1px #000,
    -1px 1px 1px #000,
    1px 1px 1px #000;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s ease, color 0.3s ease;
}

.navLink:hover,
.navLink:focus {
  color: #fcd5f9;
  text-shadow:
    -1px -1px 1px #000,
    1px -1px 1px #000,
    -1px 1px 1px #000,
    1px 1px 1px #000;
}

.active {
  text-decoration: #fefefe;
}

.hamburger {
  display: none;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 15px;
  z-index: 102;
  background-color: transparent;
  border: none;
  padding: 10px;
  margin: 0 auto;
}

.contactButton {
  border: 2px solid #000;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  margin-left: 20px;
  margin-right: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.contactButton:hover,
.contactButton:focus {
  background-color: #000;
  color: #fff;
}

@media (max-width: 855px) {
  .hamburger {
    display: block;
  }

  .navLinks {
    display: none;
  }
}
