/* HamburgerMenu.css */
.hamburger-menu-container {
    position: relative;
    z-index: 100;
  }
  
  .hamburger-icon {
    background: none;
    border: none;
    cursor: pointer;
    color: #b2b2b2;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 102;
  }
  /* Add box-shadow to each line of the hamburger menu icon */
.hamburger-icon .line {
  width: 30px;
  height: 3px;
  background-color: #fff;
  margin: 6px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Add box-shadow */
}
  
  /* Make the menu cover the entire screen */
  .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.574);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 101;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .menu-links {
    display: flex;
    flex-direction: column;
    gap: 30px;
    text-align: center;
  }
  
  .menu-links a {
    color: white;
    font-size: 1.8rem;
    text-decoration: none;
    font-weight: bold;
  }
  
  .menu-links a:hover {
    color: #ffd700;
  }
  
  /* Hide the hamburger menu icon on larger screens */
  @media (min-width: 855px) {
    .hamburger-icon {
      display: none;
    }
  }
  