.addNew{
    margin: 0;
    padding: 0;
    min-height: 100vh;
    background: #ffffff;
    text-align: center;
}

.addnewContainer{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 600px;
    margin-left: 200px;
    padding: 30px 15px;
    text-align: center;
}

.login{
    margin: 0;
    padding: 0;
    min-height: 100vh;
    background: #001f3f;

    text-align: center;
}

.loginContainer{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 15px;
    text-align: center;

}

.headingLogin{
    font-family: 'Montserrat', sans-serif;
    font-size: 36px;
    color: #f8f4f4;
    margin-bottom: 40px;
    margin-top: 20px;
    animation: slideInFromRight 1.5s forwards;
}

.addNewHeader {
    font-family: 'Montserrat', sans-serif;
    font-size: 36px;
    color: #2B2B2B;
    margin-bottom: 20px;
  }
  

.loginContainer .button {
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 223px;
    font-weight: 400; 
    margin-top: 20px;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif; 
    height: 45px;
}

.customButton {
    background-color: #007FFF; /* Deep blue color */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-family: 'Roboto', sans-serif; /* You can choose your preferred font */
    font-size: 16px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s;
}

.customButton:hover {
    background-color: #0059b2; /* Slightly darker blue on hover */
}

.customButton:active {
    background-color: #004080; /* Even darker blue when pressed */
}
.notchedOutline {
    border-color: white !important;
  }

  @keyframes highlight {
    from {
      background-color: #003366;
    }
    to {
      background-color: #0059b2dc;
    }
  }
  
  .highlight {
    animation: highlight 0.6s forwards;
  }
  
  
.smallListItem {
  width: 100%; /* Ensures it stretches to sidebar width */
  padding: 10px 0; /* Reduced padding */
  display: flex;
  justify-content: center;
  background-color: #0057b3d5;

}

.smallCard {
  width: 100%;
  background-color: #0056b3;
  color: white;
  display: flex;
  
  cursor: pointer;
  padding: 10px 20px; /* Smaller padding */
}

.smallCardContent {
  display: flex;
 
}

.smallIcon {
  font-size: 20px; /* Smaller icon size */
}

.smallText {
  color: white;
  fontWeight: bold;
  fontSize: 14px; /* Smaller font size */
  marginTop: 5px; /* Reduced margin top */
}

.gallery-image:hover {
  transform: scale(1.05);
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px; /* Added padding for spacing */
  max-width: 80%; /* Adjusted for max width */
  max-height: 90%; /* New max height to avoid touching the edges */
  background-color: #fff; /* Background to highlight the modal area */
  border-radius: 8px; /* Rounded corners for aesthetics */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Soft shadow for depth */
}

.img {
  max-width: 100%; /* Allows image to fill modal */
  max-height: 75%; /* Restricts height within modal */
  object-fit: contain; /* Keeps image proportions */
}
.img:hover {
  transform: scale(1.05); /* Scales the image to 105% of its size on hover */
}
.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: white;
  cursor: pointer;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 30px;
  color: white;
  cursor: pointer;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}