/* Slider.module.css */

.sliderContainer {
  position: relative;
}

.slide {
  position: relative;
}

.slide img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.imageContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0066ff4f;
  backdrop-filter: blur(6px);
}

.foregroundText {
  position: absolute;
  z-index: 1;
  font-family: 'Montserrat', sans-serif;
  font-size: 100px;
  color: #ffffff;
  margin-top: -160px;
  margin-left: -67px;
  left: 42%;
  transform: translateX(-50%);
  transition: all 1.2s ease-in-out;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: red;
}

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 12px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Responsive Design: Adjust styles for smaller screens */
@media (max-width: 1024px) {
  .foregroundText {
    font-size: 85px;
    margin-top: -130px;
    left: 40%;
  }
}

@media (max-width: 768px) {
  .foregroundText {
    font-size: 65px;
    margin-top: -120px;
    left: 38%;
  }
}

@media (max-width: 480px) {
  .foregroundText {
    font-size: 45px;
    margin-top: -80px;
    left: 35%;
  }
}
