.projectsContainer {
  background-color: rgb(255, 255, 255);
}

.heading {
  font-weight: bolder;
  text-align: center;
  padding: 30px;
  position: relative;
  z-index: 1;
  font-family: 'Termina', sans-serif;
  font-size: 48px;
  color: rgb(0, 0, 0); /* Changed to white */
  margin-bottom: 2px;
  margin-top: 60px;
}

.projects {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px;
  padding: 0 5%;
  padding-bottom: 20px;
  margin-top: 50px;
  margin-bottom: 100px;
}

@media (max-width: 1024px) {
  .projects {
    gap: 30px;
    padding: 0 5%;
  }
}

@media (max-width: 800px) {
  .projects {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 500px) {
  .projects {
    grid-template-columns: 1fr;
  }
}

.projectCard {
  position: relative;
  text-decoration: none;
  width: 100%;
  height: auto;
  aspect-ratio: 1 / 1;  
}

.projectImage {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover; 
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  border-radius: 8px;
  transition: opacity 0.3s;
  font-family: 'Inter', sans-serif;
  font-size: 1.2em;
}

.projectCard:hover .overlay {
  opacity: 1;
}

.projectTitle {
  font-weight: bold;
  font-size: 1.8em;
  margin-bottom: 10px;
}

.readMore {
  font-weight: bold;
  color: #FF6347;
  font-size: 1.2em;
}
