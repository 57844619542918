.loadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  color: white;
  font-size: 20px;
  z-index: 100;
}


.loading-spinner {
  border: 8px solid #f3f3f3;                   
  border-top: 5px solid #0056b3;                
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}
.loadingText{
  font-size: 26px;
  margin-top: 10px;
}
.countdown {
  margin-top: 30px; /* Spacing above the countdown */
  font-size: 24px; /* Larger font size for visibility */
  color: red; /* Red color for the countdown text */
  font-weight: bold; /* Bold font weight for emphasis */
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
