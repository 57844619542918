.project-details {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: #ffffff;
  text-align: center;
}

.project-detail-container {
  width: 50%;
  margin: 20px auto;
  font-family: Arial, sans-serif;
}

.project-title {
  font-family: 'Termina', sans-serif;
  font-size: 36px;
  color: #2B2B2B;
  margin-bottom: 30px;
  margin-top: 60px;
}

.info-block {
  flex-basis: 22%;  /* Adjust the width of each block */
  margin: 10px 0;
  text-align: center;
}

.info-title {
  font-size: 16px;
  color: #666;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold; 
  margin-bottom: 20px;
  display: block;
  flex-basis: 22%;  /* Adjust the width of each block */

}

.info-value {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;  /* Match the size for consistency */
  color: #333;
  display: block;
  margin-top: 1px;
}

.project-image {
  width: 55%;
  height: auto;
  display: block;
  margin: 20px auto; /* Adjusted margin for consistency */
  max-width: 100%;
  margin-top: 20px;
}

.photo-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
}

.gallery-image {
  width: 100%;
  height: auto;
  cursor: pointer;
  transition: transform 0.3s;
}

.gallery-image:hover {
  transform: scale(1.05);
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lightbox-image {
  max-width: 80%;
  max-height: 80%;
}

.close {
  position: absolute;
  top: 20px;
  right: 30px;
  color: white;
  font-size: 40px;
  cursor: pointer;
}

.arrow {
  position: absolute;
  top: 50%;
  color: white;
  font-size: 50px;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  left: 20px;
}

.right-arrow {
  right: 20px;
}

.not-found {
  font-size: 24px;
  color: red;
  text-align: center;
}

a {
  color: blue;
  text-decoration: none;
}

.project-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  font-family: 'Ariel', sans-serif;
}



.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.not-found-image {
  max-width: 100%;
  height: auto;
}

.back-button {
  position: absolute;
  top: 630px; /* Adjust the top position */
  left: 212px; /* Adjust the left position */
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 223px;
  font-weight: 400; 
  margin-top: 20px;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif; 
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.back-button:hover {
  background-color: #0056b3;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.loading-spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #0056b3;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.info-row {
  display: flex;
  justify-content: space-around;
  width: 80%;
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.info-value a {
  color: #007bff;
  text-decoration: none;
}

.project-description {
  font-size: 18px;
  line-height: 1.6;
  text-align: justify;
  margin: 20px 10%;
  width: 80%;
}
@media (max-width: 1200px) {
  .info-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .info-block {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 720px) {
  .info-title {
    font-size: 14px;
  }

  .project-title {
    font-size: 28px;
  }

  .project-description {
    font-size: 16px;
    width: 90%;
  }

  .photo-gallery {
    grid-template-columns: repeat(2, 1fr);
  }

  .back-button {
    width: 180px;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .info-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .left-arrow {
    top: 72%;
  }
  
  .right-arrow {
    top: 72%;
  }
  .info-block {
    width: 100%;
    margin-bottom: 10px;
  }

  .info-title {
    font-size: 13px;
  }

  .project-title {
    font-size: 28px;
  }

  .project-description {
    font-size: 14px;
    width: 75%;
  }

  .photo-gallery {
    grid-template-columns: 1fr;
  }

  .back-button {
    width: 150px;
    font-size: 14px;
  }
}
