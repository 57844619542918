.contact {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: #ffffff;
  text-align: center;
  overflow-x: hidden; /* Add this line to prevent horizontal overflow */
}

.contactInside {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box; /* Add this line to include padding in the element's total width */
}

.contactContainer {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px;
  width: 100%;
  max-width: 900px;
  box-sizing: border-box; /* Add this line to include padding in the element's total width */
}

.contactHeading {
  font-family: 'Termina', sans-serif;
  font-size: 36px;
  color: #2B2B2B;
  margin-bottom: 20px;
}

.contactText {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.contractorSection {
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-family: 'Ariel', sans-serif;
  font-variant: small-caps;
  font-weight: 300;
  font-size: 18px;
}

.formArea {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  margin: 20px auto;
  max-width: 800px;
  box-sizing: border-box; /* Add this line to include padding in the element's total width */
}

.contactForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contactForm input,
.contactForm select,
.contactForm textarea,
.contactForm button {
  width: 100%;
  padding: 10px 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  font-size: 18px;
  box-sizing: border-box; /* Add this line to include padding in the element's total width */
  line-height: normal;
  height: 40px;
}

.contactForm textarea {
  grid-column: span 2;
  height: auto;
  padding: 10px;
}

.contactForm button {
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 223px;
  font-weight: 400;
  margin-top: 20px;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  height: 45px;
}

.contactForm button:hover {
  background-color: #004494;
}

.iconTextGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.iconTextItem {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.icon {
  margin-bottom: 10px;
  margin-right: 10px;
}

.text {
  text-align: center;
}

@media (max-width: 768px) {
  .iconTextGrid {
    flex-direction: column;
    align-items: center;
  }

  .iconTextItem {
    flex-direction: row;
    margin-bottom: 10px;
  }

  .icon {
    margin-right: 10px;
    margin-bottom: 0;
  }

  .text {
    text-align: left;
  }

  .MuiCard-root {
    width: 100%;
    margin-bottom: 20px;
  }
}
